// @flow

import React, { Component } from 'react'
import './style.scss'

type Props = {
  direction: string,
}

export default class SliderArrow extends Component<Props> {
  render() {
    return (
      <div className="slider-arrow">
        <div className="circle">
          <svg
            width="30px"
            height="30px"
            viewBox="0 0 30 30"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-963.000000, -959.000000)" fill="#727272">
                <g transform="translate(978.000000, 974.000000) translate(-978.000000, -974.000000) translate(963.000000, 959.000000)">
                  <g>
                    <path d="M14.999988,-1.2e-05 C6.715728,-1.2e-05 -1.2e-05,6.715728 -1.2e-05,14.999988 C-1.2e-05,23.284248 6.715728,29.999988 14.999988,29.999988 C23.284248,29.999988 29.999988,23.284248 29.999988,14.999988 C29.999988,6.715728 23.284248,-1.2e-05 14.999988,-1.2e-05 M14.999988,2.099988 C16.742448,2.099988 18.431568,2.440788 20.020608,3.112848 C21.556488,3.762468 22.936248,4.692948 24.121668,5.878308 C25.307088,7.063728 26.237508,8.443488 26.887128,9.979368 C27.559188,11.568408 27.899988,13.257588 27.899988,14.999988 C27.899988,16.742448 27.559188,18.431568 26.887128,20.020608 C26.237508,21.556488 25.307088,22.936248 24.121668,24.121668 C22.936248,25.307088 21.556488,26.237508 20.020608,26.887128 C18.431568,27.559188 16.742448,27.899988 14.999988,27.899988 C13.257588,27.899988 11.568408,27.559188 9.979368,26.887128 C8.443488,26.237508 7.063728,25.307088 5.878308,24.121668 C4.692948,22.936248 3.762468,21.556488 3.112908,20.020608 C2.440788,18.431568 2.099988,16.742448 2.099988,14.999988 C2.099988,13.257588 2.440788,11.568408 3.112908,9.979368 C3.762468,8.443488 4.692948,7.063728 5.878308,5.878308 C7.063728,4.692948 8.443488,3.762468 9.979368,3.112848 C11.568408,2.440788 13.257588,2.099988 14.999988,2.099988" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={`arrow ${this.props.direction}`}>
          <div className="arrow-red">
            <svg
              width="16px"
              height="18px"
              viewBox="0 0 12 18"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                  transform="translate(-972.000000, -965.000000)"
                  fill="#da1157">
                  <g
                    className="main-stroke"
                    transform="translate(978.000000, 974.000000) translate(-978.000000, -974.000000) translate(969.000000, 968.000000)">
                    <path d="M16.7873948,4.72004883 C16.7869513,4.71893496 15.3701598,3.32034893 12.5370202,0.524290746 C11.8285337,-0.174763582 10.6798737,-0.174763582 9.97138721,0.524290746 C9.26290073,1.22340474 9.26290073,2.35686927 9.97138721,3.05598327 L11.1403052,4.20943783 L2.48081777,4.20943783 C1.47886212,4.20943783 0.666666667,5.01094852 0.666666667,5.99958976 C0.666666667,6.98823099 1.47886212,7.78974168 2.48081777,7.78974168 L11.1411518,7.78974168 L9.97144768,8.94409132 C9.2629612,9.64320532 9.26302167,10.7766698 9.97156862,11.4757242 C10.3258119,11.8252812 10.7899927,12 11.2542944,12 C11.7185357,12 12.1829583,11.8251618 12.5371411,11.4756048 L16.8035407,7.26522717 C17.1312974,6.93912116 17.3333333,6.49265727 17.3333333,5.99958976 C17.3333333,5.49810853 17.1241012,5.04502108 16.7873948,4.72004883 Z" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div className="arrow-gray">
            <svg
              width="16px"
              height="18px"
              viewBox="0 0 12 18"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                  transform="translate(-972.000000, -965.000000)"
                  fill="#727272">
                  <g
                    className="main-stroke"
                    transform="translate(978.000000, 974.000000) translate(-978.000000, -974.000000) translate(969.000000, 968.000000)">
                    <path d="M16.7873948,4.72004883 C16.7869513,4.71893496 15.3701598,3.32034893 12.5370202,0.524290746 C11.8285337,-0.174763582 10.6798737,-0.174763582 9.97138721,0.524290746 C9.26290073,1.22340474 9.26290073,2.35686927 9.97138721,3.05598327 L11.1403052,4.20943783 L2.48081777,4.20943783 C1.47886212,4.20943783 0.666666667,5.01094852 0.666666667,5.99958976 C0.666666667,6.98823099 1.47886212,7.78974168 2.48081777,7.78974168 L11.1411518,7.78974168 L9.97144768,8.94409132 C9.2629612,9.64320532 9.26302167,10.7766698 9.97156862,11.4757242 C10.3258119,11.8252812 10.7899927,12 11.2542944,12 C11.7185357,12 12.1829583,11.8251618 12.5371411,11.4756048 L16.8035407,7.26522717 C17.1312974,6.93912116 17.3333333,6.49265727 17.3333333,5.99958976 C17.3333333,5.49810853 17.1241012,5.04502108 16.7873948,4.72004883 Z" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    )
  }
}
