// @flow

import React, { Component } from 'react'
import classnames from 'classnames'

import Footer from '../Footer'

import './page.scss'

type Props = {
  children: any,
  className?: string,
}

export default class Page extends Component<Props> {
  render() {
    const { className, children } = this.props
    return (
      <div>
        <div className={classnames('website', 'page', className)}>
          <div className="page-content">{children}</div>
          <Footer />
        </div>
      </div>
    )
  }
}
