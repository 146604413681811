// @flow

import React from 'react'

export default function ExpandButton() {
  return (
    <div className="expand-button">
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35">
        <path
          fill="currentColor"
          d="M18.468 16.71L18.466 1.37a.968.968 0 0 0-1.935 0l.002 15.341-15.342-.001a.968.968 0 0 0-.001 1.936h15.343l-.001 15.342a.968.968 0 0 0 1.936-.002v-15.34h15.34a.966.966 0 0 0 .967-.967.965.965 0 0 0-.967-.968h-15.34z"
        />
      </svg>
      Expand
    </div>
  )
}
