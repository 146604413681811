// @flow

import React from 'react'

const ClockIcon = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <g
      transform="translate(1 1)"
      stroke="currentColor"
      fill="none"
      fillRule="evenodd">
      <circle cx="7" cy="7" r="7" />
      <path strokeLinecap="round" d="M7 2.66V7l2.74 1.776" />
    </g>
  </svg>
)

export default ClockIcon
