// @flow

import React, { type Node } from 'react'

import { Link } from 'gatsby'
import HeaderLogo from './HeaderLogo'

import './style.scss'

type Props = {
  children: Node,
  theme?: string,
}

const Header = ({ theme = 'mainframe', children }: Props) => (
  <header className={`header ${theme}`}>
    <Link to="/" className="header-logo">
      <HeaderLogo theme={theme} />
    </Link>

    <input type="checkbox" id="menu-btn" />

    <label className="menu-button" htmlFor="menu-btn">
      <span className="nav-icon" />
    </label>

    <nav className="menu-nav">
      <HeaderLogo theme={theme} inverted />
      {children}
    </nav>
  </header>
)

export default Header
