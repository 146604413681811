// @flow

import React, { Component } from 'react'
import Helmet from 'react-helmet'
import {
  ImageBoard,
  Map,
  OldButton as Button,
  VideoModal,
} from 'shared-components'
import BlogArea from '../components/BlogArea'
// import Script from 'react-load-script'
import Layout from '../components/Layout'
import POSTS from '../posts.json'

import './brazil.scss'

type State = {
  openModal: boolean,
  hackathonId: string,
  openVideo: string,
}

const splitData = (source: Array<Object>, bigChunk: number) => {
  let i, j
  const result = []
  const chunk = 3

  const data = source.slice(0, bigChunk)

  for (i = 0, j = data.length; i < j; i += chunk) {
    result.push(data.slice(i, i + chunk))
  }

  return result
}

class BrazilPage extends Component<{}, State> {
  state = {
    openModal: false,
    hackathonId: '',
    openVideo: '',
  }

  openModal = (videoId: string) => {
    this.setState({ openVideo: videoId })
  }

  getJobs = () => {
    window.whr_embed &&
      window.whr_embed(287989, {
        base: 'jobs',
        zoom: 'country',
        grouping: 'none',
      })
  }

  posts = POSTS.map(({ title, createdAt, updatedAt, virtuals, uniqueSlug }) => {
    return {
      title,
      createdAt: updatedAt || createdAt,
      subtitle: virtuals.subtitle,
      image: virtuals.previewImage.imageId
        ? `https://cdn-images-1.medium.com/max/800/${
            virtuals.previewImage.imageId
          }`
        : null,
      url: `https://blog.mainframe.com/${uniqueSlug}`,
      readingTime: Math.ceil(virtuals.readingTime),
    }
  })

  render() {
    return (
      <Layout useContainer={false} className="brazil-page">
        <Helmet>
          <title>{'A Mainframe está chegando no Brasil'}</title>
        </Helmet>
        {/*<Script
          url="https://www.workable.com/assets/embed.js"
          onLoad={this.getJobs}
        />*/}
        <div className="flag" />
        <div className="session">
          <div className="top-area">
            <div className="content">
              <div className="header-container">
                <div className="big-title">
                  <h1>{'A Mainframe está'}</h1>
                  <h1>{'chegando no Brasil'}</h1>
                </div>
                <p>
                  {
                    'E procuramos pessoas talentosas para trabalhar no escritório de São Paulo. Quer fazer parte do nosso time?'
                  }
                </p>
                <Button className="mf-button light">
                  <a className="white" href={'#jobs'}>
                    {'Conheça nossas vagas'}
                  </a>
                </Button>
              </div>
            </div>
            <div className="flag" />
          </div>
        </div>
        <div className="session white-bg">
          <div className="container">
            <div className="content">
              <div className="top-video">
                <div className="video">
                  <div
                    className="video-thumb"
                    onClick={() => this.openModal('b-2nm96AIOQ')}
                  />
                </div>
                <div className="text">
                  <h1>{'Mensagem do pessoal da Mainframe'}</h1>
                  <p>
                    {
                      'Está interessado em fazer parte do nosso escritório? Assista aqui o vídeo que fizemos pra você.'
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="session gray-bg" id="jobs">
          <div className="container">
            <div className="content">
              <div className="jobs">
                <div className="jobs-container">
                  <h1>{'Faça parte do nosso time!'}</h1>
                  {/*<div id="whr_embed_hook" />*/}
                  <p>{'Mande seu currículo para o email jobs@mainframe.com'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="session white-bg">
          <div className="container">
            <div className="content">
              <div className="map-section">
                <div className="text">
                  <h1>{'Nosso escritório'}</h1>
                  <p>{'Spaces Vila Madalena'}</p>
                  <p>{'R. Cap. Antônio Rosa, 409,'}</p>
                  <p>{'Vila Madalena, São Paulo'}</p>
                  <p>{'Sala 228'}</p>
                </div>
                <div className="rendermap">
                  <Map />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="session gray-bg" id="events">
          <div className="container">
            <div className="content">
              <div className="past-events">
                <h1>{'Eventos Passados'}</h1>
                <ImageBoard data={splitData(p1, 9)} square={true} />
              </div>
            </div>
          </div>
        </div>
        <div className="session white-bg" id="mainframe">
          <div className="container">
            <div className="content">
              <div className="what-mainframe">
                <div className="text">
                  <h1>{'O que é a Mainframe?'}</h1>
                  <p>
                    {
                      'Nós, da Mainframe, estamos construindo uma plataforma para transformar o desenvolvimento de aplicativos descentralizados (que nós gostamos de chamar de “dapps”) em algo fácil. Nós entregamos o framework completo, funcionalidade e suporte para developers navegarem com sucesso no mundo da web3. O design do nosso network é único e faz com que privacidade e segurança sejam automáticos. Isso impede qualquer tipo de censura ou boicote. Nesse cenário as regras mudam e a dependência de terceiros também. Em resumo, nós estamos construindo um novo tipo de internet, onde os desenvolvedores e os usuários tem controle de seus dados e destino.'
                    }
                  </p>
                </div>
                <div className="video">
                  <div
                    className="video-thumb"
                    onClick={() => this.openModal('EAWkjEGQoI4')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="session gray-bg" id="braziloffice">
          <div className="container">
            <div className="content">
              <div className="why-brazil">
                <div className="why-brazil-img" />
                <div className="text">
                  <h1>{'Por que no Brasil?'}</h1>
                  <p>
                    {
                      'Os brasileiros são hackers e a gente sabe disso. 3 executivos da Mainframe já moraram no Brasil por vários anos. Nós falamos português fluente, nós amamos a cultura e o povo brasileiro. Nós sabemos que vocês trabalham duro. Sabemos que vocês sempre dão um jeito para fazer as coisas funcionarem melhor. Vocês cresceram aprendendo a dar um jeitinho, sem precisar de muito para isso. Nós entendemos que dar um jeitinho pra tudo é ser hacker por natureza e nós admiramos isso em vocês.'
                    }
                  </p>
                  <p>
                    {
                      'O talento é distribuído igualmente, mas oportunidade não. Está muito claro para nós que o Brasil é um país culturalmente rico, criativo e cheio de potencial. Queremos contribuir para gerar mais oportunidades e desenvolver novas soluções junto com vocês. As outras empresas americanas de tecnologia não sabem o que estão perdendo por não estarem aqui, trabalhando com vocês. Nós não vamos cometer o mesmo erro. Nós acreditamos na habilidade que vocês têm para criar soluções e queremos potencializá-las para o mundo todo. Nós precisamos estar no Brasil. E é por isso que já começamos.'
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="session area-blog white-bg" id="blog">
          <div className="container px-0">
            <h1 className="text-center">{'Blog e vídeos'}</h1>
            <BlogArea />
          </div>
        </div>
        <VideoModal
          isOpen={this.state.openVideo !== ''}
          onClose={() => this.setState({ openVideo: '' })}
          videoId={this.state.openVideo}
        />
      </Layout>
    )
  }
}

const p1 = [
  {
    imgurl:
      'https://firebasestorage.googleapis.com/v0/b/mainframe-website.appspot.com/o/brazil%2F42932600_215658189146289_8967643358328474536_n.jpg?alt=media&token=bad4a42a-67e3-435e-b7ca-c287801b25ad',
    type: 'img',
    kind: 'img',
    id: 1,
  },
  {
    imgurl:
      'https://firebasestorage.googleapis.com/v0/b/mainframe-website.appspot.com/o/brazil%2F43087026_127182811589871_305023511203089462_n.jpg?alt=media&token=e13dbd34-f2e6-4e71-bdda-f1e43d4fba7e',
    type: 'img',
    kind: 'img',
    id: 2,
  },
  {
    imgurl:
      'https://firebasestorage.googleapis.com/v0/b/mainframe-website.appspot.com/o/brazil%2F43156280_176292259946712_8880867414562782849_n.jpg?alt=media&token=4296bb70-18a9-44a0-8113-59d748fef945',
    type: 'img',
    kind: 'img',
    id: 3,
  },
  {
    imgurl:
      'https://firebasestorage.googleapis.com/v0/b/mainframe-website.appspot.com/o/brazil%2F43737623_653232441738707_1626982806740718011_n.jpg?alt=media&token=c64020ac-8e03-4c49-a033-98d7d1a65257',
    type: 'img',
    kind: 'img',
    id: 4,
  },
  {
    imgurl:
      'https://firebasestorage.googleapis.com/v0/b/mainframe-website.appspot.com/o/brazil%2F43914952_918817544996010_1435641725733539949_n.jpg?alt=media&token=66428aef-d523-4e17-8cbf-8e9548803bec',
    type: 'img',
    kind: 'img',
    id: 5,
  },
  {
    imgurl:
      'https://firebasestorage.googleapis.com/v0/b/mainframe-website.appspot.com/o/brazil%2F44631399_2122687124460117_3093256382482265499_n.jpg?alt=media&token=0194cac2-638c-4902-842c-74fba0eda464',
    type: 'img',
    kind: 'img',
    id: 6,
  },
  {
    imgurl:
      'https://firebasestorage.googleapis.com/v0/b/mainframe-website.appspot.com/o/brazil%2F45447016_364927567597861_2869011200759408299_n.jpg?alt=media&token=bf60dbc6-fc08-45f3-9eeb-0a84a9db5b0e',
    type: 'img',
    kind: 'img',
    id: 7,
  },
  {
    imgurl:
      'https://firebasestorage.googleapis.com/v0/b/mainframe-website.appspot.com/o/brazil%2F45570617_2346714308894619_6476361789376439678_n.jpg?alt=media&token=a55e44ed-1a59-43bc-8cf2-d244b3c295ef',
    type: 'img',
    kind: 'img',
    id: 8,
  },
  {
    imgurl:
      'https://firebasestorage.googleapis.com/v0/b/mainframe-website.appspot.com/o/brazil%2F46538997_212823389597098_8854477763292340963_n.jpg?alt=media&token=6f4b8619-2f37-430a-8384-0e11aae41c22',
    type: 'img',
    kind: 'img',
    id: 9,
  },
]

export default BrazilPage
