// @flow
import React, { Children, useState, type Node } from 'react'
import classnames from 'classnames'
import CarouselSlide from './CarouselSlide'
import Arrow from './Arrow'
import './styles.scss'

type Props = {
  arrows?: boolean,
  children: Node,
  className?: string,
}

export type CarouselContextControls = {
  next: () => void,
  prev: () => void,
}

export const CarouselContext = React.createContext<CarouselContextControls>({})

const Carousel = ({ arrows, children, className }: Props) => {
  const slides = Children.toArray(children)
  const { currentSlide, next, prev, hasNext, hasPrev, goTo } = useCarousel(
    slides.length,
  )

  return (
    <CarouselContext.Provider value={{ next, prev }}>
      <div className={classnames('carousel', className)}>
        {slides.map((slide, key) => (
          <input
            key={key}
            type="radio"
            id={`post-slide-${key}`}
            className="carousel-activator"
            readOnly
            checked={key === currentSlide}
          />
        ))}

        <div className="carousel-track-wrapper">
          {arrows && (
            <>
              {hasPrev && <Arrow prev onClick={prev} />}
              {hasNext && <Arrow next onClick={next} />}
            </>
          )}
          <div className="carousel-track">{children}</div>
        </div>

        <div className="carousel-indicators">
          {slides.map((slide, key) => (
            <label
              key={key}
              htmlFor={`post-slide-${key}`}
              onClick={() => goTo(key)}
            />
          ))}
        </div>
      </div>
    </CarouselContext.Provider>
  )
}

export default Carousel

export { Carousel, CarouselSlide }

function useCarousel(maxItems: number) {
  const [currentSlide, setSlide] = useState(0)
  const normalizeSlide = (value: number): void => {
    if (value >= maxItems) setSlide(0)
    else if (value < 0) setSlide(maxItems - 1)
    else setSlide(value)
  }

  const hasNext = currentSlide + 1 < maxItems
  const hasPrev = currentSlide > 0

  return {
    currentSlide,
    hasNext,
    hasPrev,
    next: () => normalizeSlide(currentSlide + 1),
    prev: () => normalizeSlide(currentSlide - 1),
    goTo: (slide: number) => normalizeSlide(slide),
  }
}
