// @flow

import React from 'react'

import '../../layouts/_fonts.scss'

type Props = {
  children?: ?any,
}

const Fonts = ({ children }: Props) => <>{children}</>

export default Fonts
