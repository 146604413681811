// @flow

import React from 'react'
import InfoBoxModal from './InfoBoxModal'

import './styles.scss'

type Props = {
  alt?: string,
  thumb?: string,
  src: string,
}

const InfoBoxImageModal = ({ alt = '', thumb, src }: Props) => {
  return (
    <InfoBoxModal thumb={thumb || src}>
      <img src={src} alt={alt} />
    </InfoBoxModal>
  )
}

export default InfoBoxImageModal
