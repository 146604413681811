// @flow

import React from 'react'
import classnames from 'classnames'
import InfoBoxGrid from './InfoBoxGrid'
import InfoBoxText from './InfoBoxText'
import InfoBoxImage from './InfoBoxImage'
import InfoBoxImageModal from './InfoBoxImageModal'
import InfoBoxModal from './InfoBoxModal'

import './styles.scss'

type Props = {
  children: any,
  className?: string,
}

const InfoBox = ({ children, className }: Props) => {
  return (
    <div className={classnames('info-box row no-gutters', className)}>
      {children}
    </div>
  )
}

export default InfoBox

export {
  InfoBox,
  InfoBoxGrid,
  InfoBoxImage,
  InfoBoxImageModal,
  InfoBoxModal,
  InfoBoxText,
}
