// @flow
import React from 'react'
import moment from 'moment'
import ClockIcon from '../Icons/ClockIcon'
import ArrowIcon from '../Icons/ArrowIcon'
import { type Post } from './index'

type Props = {
  post: Post,
}

export default function PostContent({ post }: Props) {
  return (
    <a className="post" href={post.url} target="_new">
      <div className="image-container">
        <img className="image" src={post.image} alt="" />
      </div>
      <div className="post-content">
        <span className="title">{post.title}</span>
        <span className="date">{moment(post.createdAt).format('MMMM Do')}</span>
        <span className="description">{post.subtitle}</span>
        <div className="post-info">
          <span className="post-reading-time">
            {`${post.readingTime} mins`} <ClockIcon />
          </span>
          <ArrowIcon className="arrow-icon text-pink" />
        </div>
      </div>
    </a>
  )
}
