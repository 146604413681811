//URL
export const PLATFORM = process.env.PLATFORM || 'dev'

const URLS_MAPPING = {
  dev: {
    mainframe: 'http://localhost:8000',
    mainframeos: 'http://localhost:8001',
    //api: 'http://localhost:5001/mainframe-team/us-central1/staging',
    api: 'https://us-central1-mainframe-team.cloudfunctions.net/staging',
  },
  testing: {
    mainframe: 'https://mainframehq.github.io/websites/mainframe.com',
    mainframeos: 'https://mainframehq.github.io/websites/mainframeos.com',
    api: 'https://us-central1-mainframe-team.cloudfunctions.net/staging',
  },
  prod: {
    mainframe: 'https://mainframe.com',
    mainframeos: 'https://mainframeos.com',
    api: 'https://us-central1-mainframe-team.cloudfunctions.net/website',
  },
}

const URLS = URLS_MAPPING[PLATFORM]

export default URLS
