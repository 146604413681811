// @flow

import React from 'react'
import classnames from 'classnames'
import './styles.scss'

type Props = {
  children: any,
  className?: string,
}

const InfoBoxGrid = ({ children, className = '' }: Props) => {
  return (
    <div className={classnames('info-box-grid', className)}>{children}</div>
  )
}

export default InfoBoxGrid
