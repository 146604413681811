import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import './map-section.scss'
import Icon from './img/marker.svg'

/*
  Workaround `leaflet` and `react-leaflet` since leaflet don't have the
  error handling when rendering server-side
  @see https://github.com/PaulLeCam/react-leaflet/issues/495
 */
let Leaflet, Map, TileLayer, Marker
if (typeof window !== 'undefined') {
  Leaflet = require('leaflet')
  const ReactLeaflet = require('react-leaflet')
  Map = ReactLeaflet.Map
  TileLayer = ReactLeaflet.TileLayer
  Marker = ReactLeaflet.Marker
}

const MOBILE_MAP_POSITION = [-23.5673, -46.6851]
const MAP_POSITION = [-23.5673832, -46.6851831]
const ICON_POSITION = [-23.5673832, -46.6851831]
const MAP_ICON = {
  iconUrl: Icon,
  iconSize: [38, 95],
  iconAnchor: [22, 94],
  popupAnchor: [-3, -76],
}

const MapComponent = () => {
  if (typeof window !== 'undefined') {
    const renderIcon = Leaflet.icon(MAP_ICON)
    const MAP_POS = isMobile ? MOBILE_MAP_POSITION : MAP_POSITION

    return (
      <Map
        className="map"
        center={MAP_POS}
        zoom="16"
        zoomControl={false}
        scrollWheelZoom={false}>
        <TileLayer
          attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ &mdash; Powered by <a href='http://esri.maps.arcgis.com/home/index.html'>Esri</a>"
          url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
        />
        <Marker position={ICON_POSITION} icon={renderIcon} />
        <link //necessary to render the map
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.3.4/dist/leaflet.css"
          integrity="sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=="
          crossOrigin=""
        />
      </Map>
    )
  }
  return null
}

export default class MapSection extends Component {
  render() {
    return (
      <div id="map-section">
        <MapComponent />
      </div>
    )
  }
}
