// @flow

import React from 'react'

import LogoMainframeDefault from './img/logo-mainframe-default.svg'
import LogoMainframeInverted from './img/logo-mainframe-inverted.svg'
import LogoMainframeOSDefault from './img/logo-mainframeos-default.svg'
import LogoMainframeOSInverted from './img/logo-mainframeos-inverted.svg'

const themes = {
  mainframeos: {
    default: LogoMainframeOSDefault,
    inverted: LogoMainframeOSInverted,
  },
  mainframe: {
    default: LogoMainframeDefault,
    inverted: LogoMainframeInverted,
  },
}

type Props = {
  inverted?: boolean,
  theme: string,
}

const HeaderLogo = ({ inverted = false, theme }: Props) => (
  <img
    alt="logo"
    className="logo"
    src={inverted ? themes[theme].inverted : themes[theme].default}
  />
)

export default HeaderLogo
