// @flow
import React from 'react'

type Props = {
  onClick: (event: Event) => void,
}

export default function CloseButton({ onClick }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className="modal-close-button">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#303030"
          d="M25.314 24l10.413-10.415a.93.93 0 0 0-1.313-1.313L24 22.687 13.587 12.272a.93.93 0 0 0-1.315 1.313L22.687 24 12.272 34.414a.93.93 0 0 0 1.315 1.313L24 25.314l10.414 10.413a.927.927 0 0 0 1.313 0 .926.926 0 0 0 0-1.313L25.314 24z"
        />
      </g>
    </svg>
  )
}
