// @flow

import React from 'react'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import './styles.scss'
import {
  faDiscord,
  faTwitter,
  faYoutube,
  faMediumM,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'

type Props = {
  theme?: string,
}

const SocialMediaLinks = ({ theme = 'dark' }: Props) => {
  return (
    <div className={`social-media-links flex-md-column flex-xs-row ${theme}`}>
      {/* <a
        href="https://blog.mainframe.com/"
        target="_blank"
        rel="noopener noreferrer">
        <Icon icon={faMediumM} />
      </a>
      <a
        href="https://discord.gg/mhtSRz6"
        target="_blank"
        rel="noopener noreferrer">
        <Icon icon={faDiscord} />
      </a>
      <a
        href="https://twitter.com/Mainframe_HQ"
        target="_blank"
        rel="noopener noreferrer">
        <Icon icon={faTwitter} />
      </a>
      <a
        href="https://www.youtube.com/c/MainframeHQ"
        target="_blank"
        rel="noopener noreferrer">
        <Icon icon={faYoutube} />
      </a>
      <a
        href="https://github.com/MainframeHQ"
        target="_blank"
        rel="noopener noreferrer">
        <Icon icon={faGithub} />
      </a> */}
    </div>
  )
}

export default SocialMediaLinks
