// @flow

import React, { type Node } from 'react'
import classnames from 'classnames'

import './styles.scss'

type Props = {
  className?: string,
  src: string,
  children?: Node,
  onClick?: (event: Event) => void,
}

const InfoBoxImage = ({ className, src, children, onClick }: Props) => {
  return (
    <div
      className={classnames('info-box-image p-0 col-12 col-lg-6', className)}
      onClick={onClick}>
      <img src={src} alt="" className="thumb-image" />
      {children}
    </div>
  )
}

export default InfoBoxImage
