// @flow

import React from 'react'
import classnames from 'classnames'
import './styles.scss'

import { SocialMediaLinks } from 'shared-components'
import Fade from 'react-reveal/Fade'

type Props = {
  theme?: string,
}

type State = {
  height: number,
  hideSocialMediaLink: boolean,
}

export default class Sidebar extends React.Component<Props, State> {
  TRACK_MAX_HEIGHT = 200

  state = {
    height: 0,
    hideSocialMediaLink: false,
  }

  goToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  getPageHeight = (): number => (document.body ? document.body.clientHeight : 0)

  shouldHideSocialMediaLinks = (): boolean => {
    const sidebarHeight = getSidebarHeight()

    return window.scrollY / sidebarHeight <= 0.94
  }

  getProportionalHeight = (height: number): number => {
    return (height * this.TRACK_MAX_HEIGHT) / this.getPageHeight()
  }

  updateHandlePosition = (height: number): void => {
    const handle: HTMLElement | null = document.querySelector(
      '.sidebar .rail-handle',
    )
    if (handle) {
      const maxHandlePosition = this.TRACK_MAX_HEIGHT - 50
      const proportionalHeight = Math.min(height, maxHandlePosition)
      handle.style.transform = `translateY(${proportionalHeight}px)`
    }
  }

  updateSidebarHeight = (): void => {
    this.setState({
      height: getSidebarHeight(),
    })
  }

  componentDidMount(): void {
    window.addEventListener('resize', this.updateSidebarHeight)

    window.setTimeout(() => {
      const proportionalHeight = this.getProportionalHeight(window.scrollY)
      this.updateHandlePosition(proportionalHeight)
      this.updateSidebarHeight()

      this.setState({
        hideSocialMediaLink: this.shouldHideSocialMediaLinks(),
      })
    }, 500)

    document.addEventListener('scroll', () => {
      const proportionalHeight = this.getProportionalHeight(window.scrollY)
      this.updateHandlePosition(proportionalHeight)

      this.setState({
        hideSocialMediaLink: this.shouldHideSocialMediaLinks(),
      })
    })
  }

  componentWillUnmount(): void {
    document.removeEventListener('resize', this.updateSidebarHeight)
  }

  render() {
    const { hideSocialMediaLink } = this.state
    const { theme = 'mainframe' } = this.props

    return (
      <div className="sidebar-container">
        <div
          className={classnames('sidebar-track', theme)}
          style={{ height: this.state.height }}>
          {/* <div className="sidebar">
            <span className="go-to-top" onClick={this.goToTop}>
              Top
            </span>
            <div className="rail">
              <div className="rail-handle" />
            </div>

            <Fade collapse when={hideSocialMediaLink} timeout={200}>
              <SocialMediaLinks />
            </Fade>
          </div> */}
        </div>
      </div>
    )
  }
}

function getSidebarHeight(): number {
  const footer = document.querySelector('.footer')
  let bodyHeight = 0
  let paddingBottom = 800 // fallback

  if (footer) {
    paddingBottom = footer.clientHeight + 250
  }

  if (document.body) {
    bodyHeight = document.body.clientHeight
  }

  return bodyHeight - paddingBottom
}
