// @flow

import React, { useState, type Node } from 'react'
import Modal from '../Modal'
import CloseButton from './CloseButton'
import ExpandButton from './ExpandButton'
import InfoBoxImage from './InfoBoxImage'

import './styles.scss'

type Props = {
  thumb: string,
  children: Node,
}

const InfoBoxModal = ({ thumb, children }: Props) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <InfoBoxImage
      src={thumb}
      className="expandable"
      onClick={() => setOpen(true)}>
      <ExpandButton />
      <Modal
        className="info-box-modal"
        isOpen={isOpen}
        closeButton={CloseButton}
        onClose={(event: Event) => {
          event.stopPropagation()
          setOpen(false)
        }}>
        <div className="info-box-modal-body">{children}</div>
      </Modal>
    </InfoBoxImage>
  )
}

export default InfoBoxModal
