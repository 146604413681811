// @flow
import React from 'react'
import classnames from 'classnames'

type Props = {
  onClick: () => void,
  next?: boolean,
  prev?: boolean,
}

export default function Arrow({ onClick, next, prev }: Props) {
  return (
    <button
      className={classnames('arrow', {
        'arrow-next': next,
        'arrow-prev': prev,
      })}
      onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="50">
        <path
          fill="currentColor"
          d="M.678 46.324a2.05 2.05 0 0 0 0 3.045c.903.841 2.368.841 3.27 0L28.49 26.523a2.05 2.05 0 0 0 0-3.046L3.95.631C3.046-.21 1.58-.21.678.63a2.05 2.05 0 0 0 0 3.045L23.582 25 .678 46.324z"
        />
      </svg>
    </button>
  )
}
