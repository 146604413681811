// @flow

import React from 'react'
import classnames from 'classnames'
import './styles.scss'

type Props = {
  children: any,
  className?: string,
}

const InfoBoxText = ({ children, className = '' }: Props) => {
  return (
    <div className={classnames('info-box-text col-12 col-lg-6', className)}>
      <div className="info-box-text-inner">{children}</div>
    </div>
  )
}

export default InfoBoxText
