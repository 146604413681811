// @flow
import React, { type Node } from 'react'
import { Swipeable } from 'react-swipeable'
import { CarouselContext, type CarouselContextControls } from './index'
import './styles.scss'

type Props = {
  children?: Node,
}

export default function CarouselSlide({ children }: Props) {
  return (
    <CarouselContext.Consumer>
      {({ next, prev }: CarouselContextControls) => (
        <div className="carousel-slide">
          <Swipeable
            className="carousel-slide-content"
            onSwipedLeft={next}
            onSwipedRight={prev}>
            {children}
          </Swipeable>
        </div>
      )}
    </CarouselContext.Consumer>
  )
}
