// @flow

import React, { type Node } from 'react'
import classnames from 'classnames'

import { Fonts, Footer, Header, Sidebar } from 'shared-components'
import banner from '../../images/home/Mainframe_SablierBannerNoText.svg'
import './styles.scss'
import { Link } from 'gatsby'

type Props = {
  children: Node,
  useContainer?: boolean,
  useSidebar?: boolean,
  className?: string,
}

const Layout = ({
  children,
  useContainer = true,
  useSidebar = true,
  className,
}: Props) => (
  <Fonts>
    {useSidebar && <Sidebar theme={'mainframe'} />}
    <div className={classnames('website', 'page', className && className)}>

      {/* <div className="container banner d-none d-md-block">
        <img className="" src={banner} />
        <h4>Mainframe welcomes <a href="https://sablier.finance/">Sablier.finance</a> to the family! Read more <a href="https://blog.mainframe.com/mainframe-acquires-sablier-finance-8eecc8b3c69b">here</a>.</h4>
      </div> */}
      <Header theme="mainframe">
        <ul>
          <li className="home-link">
            <Link to="/" className="nav-link p-0" activeClassName="active">
              Home
            </Link>
            <hr />
          </li>
          {/*<li>*/}
          {/*  <Link*/}
          {/*    to="/roadmap"*/}
          {/*    className="nav-link p-0"*/}
          {/*    activeClassName="active"*/}
          {/*    partiallyActive>*/}
          {/*    Roadmap*/}
          {/*  </Link>*/}
          {/*  <hr />*/}
          {/*</li>*/}
          <li>
            <a
              href="https://discord.gg/mhtSRz6"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link p-0">
              Community
            </a>
            <hr />
          </li>
          <li>
            <a
              href="https://blog.mainframe.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link p-0">
              Blog
            </a>
            <hr />
          </li>
        </ul>
      </Header>
      <div className="page-content">
        <div className="site-overflow">
          <main>
            {useContainer ? (
              <div className={classnames('container', className)}>
                {children}
              </div>
            ) : (
              children
            )}
          </main>
          <Footer />
        </div>
      </div>
    </div>
  </Fonts>
)

export default Layout
