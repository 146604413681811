// @flow

import React, { type Node } from 'react'
import classnames from 'classnames'
import './styles.scss'

import { Modal as ROModal } from 'react-overlays'
import ModalBackdrop from './ModalBackdrop'
import ModalCloseButton from './ModalCloseButton'

const modalStyle = {
  position: 'fixed',
  zIndex: 99999999,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}

type Props = {
  isOpen?: boolean,
  onClose?: (event: Event) => void,
  children: Node,
  className?: string,
  closeButton?: any,
}

export default class Modal extends React.Component<Props> {
  static defaultProps = {
    isOpen: false,
  }

  close = (event: Event) => {
    this.props.onClose && this.props.onClose(event)
  }

  render() {
    const CloseButton = this.props.closeButton || ModalCloseButton

    return (
      <ROModal
        style={modalStyle}
        show={this.props.isOpen}
        onHide={this.close}
        onEscapeKeyDown={this.close}
        onBackdropClick={this.close}
        renderBackdrop={ModalBackdrop}
        className={classnames(this.props.className)}>
        <>
          <CloseButton onClick={this.close} />
          {this.props.children}
        </>
      </ROModal>
    )
  }
}
