// @flow

import React, { Component } from 'react'
import classnames from 'classnames'

import './style.scss'

type Props = {
  className?: ?string,
  light?: boolean,
  children: ?any,
}

export default class Button extends Component<Props> {
  render() {
    const { className, children, light, ...other } = this.props

    return (
      <button
        className={classnames('mf-button', { light }, className)}
        {...other}>
        <span>{children}</span>
      </button>
    )
  }
}
