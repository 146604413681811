// @flow

import React, { Component } from 'react'

import Modal from '../Modal'
import './style.scss'

type Props = {
  data: Array<Array<Object>>,
  square?: boolean,
}

type State = {
  openItem: string,
  modalOpen: boolean,
}

export default class ImageBoard extends Component<Props, State> {
  state = {
    modalOpen: false,
    openItem: '',
  }

  openModal = () => {
    this.setState({ modalOpen: true })
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  onPress = (item: Object) => {
    this.setState({ openItem: item.imgurl }, this.openModal)
  }

  render() {
    return (
      <div className="image-board">
        <div className="items">
          {this.props.data.map((subArray, index) => {
            return (
              <div
                key={`row-${index}`}
                className={`items-row row-${index % 3}`}>
                {subArray.map(item => {
                  return (
                    <div
                      onClick={() => {
                        this.onPress(item)
                      }}
                      className={`item item-${item.id}`}
                      key={item.id}>
                      <span
                        style={{
                          backgroundImage: `url(${item.imgurl})`,
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}
          <Modal isOpen={this.state.modalOpen} onClose={this.closeModal}>
            <div className="image-modal-container">
              <img
                src={this.state.openItem}
                className="image-modal"
                alt={this.state.openItem}
              />
            </div>
          </Modal>
          {/*<Modal images={[this.state.openItem]} ref={this.setModalRef} />*/}
        </div>
      </div>
    )
  }
}
