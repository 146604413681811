// @flow
import React from 'react'
import classnames from 'classnames'
import POSTS from '../../posts.json'
import { Carousel, CarouselSlide } from '../Carousel'
import PostContent from './PostContent'

import './styles.scss'

export type Post = {
  url: string,
  image: string | null,
  title: string,
  subtitle: string,
  createdAt: string,
  readingTime: number,
}

type Props = {
  className?: string,
}

const posts: Array<Post> = formatPosts(POSTS)
const chunkedPosts: Array<Array<Post>> = chunked(posts, 3)
const mobilePosts: Array<Post> = posts.slice(0, 3)

const BlogArea = ({ className }: Props) => {
  return (
    <div className={classnames('area-blog', className)}>
      <Carousel className="desktop container px-0" arrows>
        {chunkedPosts.map((chunk, key) => (
          <CarouselSlide key={key}>
            {chunk.map(post => (
              <PostContent post={post} key={post.url} />
            ))}
          </CarouselSlide>
        ))}
      </Carousel>

      <Carousel className="mobile container px-0">
        {mobilePosts.map(post => (
          <CarouselSlide key={post.url}>
            <PostContent post={post} />
          </CarouselSlide>
        ))}
      </Carousel>
    </div>
  )
}

export default BlogArea

function chunked<T>(array: Array<T>, size): Array<Array<T>> {
  const chunks = []

  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size))
  }

  return chunks
}

function formatPosts(posts: Array<any>): Array<Post> {
  return posts.map(({ title, createdAt, updatedAt, virtuals, uniqueSlug }) => ({
    title: title.replace(' – Mainframe . Official blog', '').replace('ó', 'o'),
    createdAt: updatedAt || createdAt,
    subtitle: virtuals.subtitle,
    image: virtuals.previewImage.imageId
      ? `https://cdn-images-1.medium.com/max/800/${
          virtuals.previewImage.imageId
        }`
      : null,
    url: `https://blog.mainframe.com/${uniqueSlug}`,
    readingTime: Math.ceil(virtuals.readingTime),
  }))
}
