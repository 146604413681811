// @flow

import React from 'react'

import YouTube from 'react-youtube'
import Modal from './index'
import './styles.scss'

type Props = {
  isOpen?: boolean,
  onClose?: () => void,
  videoId: string,
}

const VideoModal = ({ isOpen, onClose, videoId }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <YouTube
      videoId={videoId}
      className="video-modal"
      opts={{
        playerVars: {
          autoplay: 1,
        },
      }}
      containerClassName="video-modal-container"
    />
  </Modal>
)

export default VideoModal
