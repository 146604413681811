import React from 'react'
import './styles.scss'

const ModalCloseButton = props => (
  <span className="modal-close-button" {...props}>
    &times;
  </span>
)

export default ModalCloseButton
