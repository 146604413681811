// @flow

import React from 'react'

import './styles.scss'

import SocialMediaLinks from '../SocialMediaLinks'
import NewsletterInput from '../NewsletterInput'

import logo from '../../images/mainframe-logo.svg'

import URLS from '../../urls'

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="row no-gutters">
        <div className="col-12 col-md-2 mb-4 mb-md-0 order-first">
          <SocialMediaLinks theme="light" />
        </div>

        <div className="col-6 col-md-3 col-lg-2 mb-4 mb-md-0">
          <h3>Company</h3>
          <ul className="m-0 p-0">
            {/*<li>*/}
            {/*  <a href={`${URLS.mainframeos}/team/`}>Team</a>*/}
            {/*</li>*/}
            <li>
              <a href="https://blog.mainframe.com/">Blog</a>
            </li>
            <li>
              <a href={`${URLS.mainframe}/branding/`}>Branding</a>
            </li>
            <li>
              <a href={`${URLS.mainframe}/contact/`}>Contact</a>
            </li>
          </ul>
        </div>

        <div className="col-6 col-md-3 col-lg-2 mb-4 mb-md-0">
          <h3>Developers</h3>
          <ul className="m-0 p-0">
            <li>
              <a
                href="https://discord.gg/mhtSRz6"
                target="_blank"
                rel="noopener noreferrer">
                Community
              </a>
            </li>
            <li>
              <a href={`https://github.com/MainframeHQ`}>Github</a>
            </li>
          </ul>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-md-0 offset-lg-2">
          <h3>News &amp; announcements</h3>
          <NewsletterInput />
        </div>
      </div>
    </div>

    <div className="copyright float-none float-md-right">
      <p>
        © Mainframe 2020. All rights reserved.{' '}
        <span className="terms-and-privacy">
          <a href={`${URLS.mainframe}/legal/terms`}>Terms</a> &amp;{' '}
          <a href={`${URLS.mainframe}/legal/privacy`}>Privacy</a>
        </span>
      </p>

      <img src={logo} alt="Mainframe's logo" />
    </div>
  </footer>
)

export default Footer
